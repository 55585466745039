import React, { useContext } from "react";
import Styles from "./UserProfileInfo.module.scss";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import AvatarImage from "../../utils/avatar-image/AvatarImage";
import Typography from "@vahak/core-ui/dist/components/Typography";
import EllipsisText from "../../EllipsisText/EllipsisText";
import UserRating from "./user-rating/UserRating";

//Icons
import BankVerifiedIcon from "@vahak/core/dist/icons/Rupee-Green.svg";
import VerifiedIcon from "@vahak/core/dist/icons/verified-tick.svg";
import MembershipCrown from "@vahak/core/dist/icons/MembershipCrown.svg";
import MembershipTag from "@vahak/core/dist/icons/VahakMember.svg";
import Phone from "@vahak/core/dist/icons/Phone.svg";

import { CompanyServiceType, CompanyServiceTypeMap } from "@vahak/core/dist/_services";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { useRouter } from "next/router";
import { ROUTES } from "@vahak/core/dist/constants";
import { AppContext } from "@vahak/core/dist/app-context";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";
import { ADMIN_OPERATION } from "../../../constants/adminOperation";
import IfCanAccess from "../../admin/IfCanAccess/IfCanAccess";

interface UserProfileInfoProps {
    id?: string;
    companyId: number;
    companyLogo: string;
    name: string;
    isBankVerified: boolean;
    isVerified: boolean;
    isAadhaarVerified: boolean;
    isGstVerified: boolean;
    isPanVerified: boolean;
    isMember: boolean;
    rating: number;
    serviceType: string;
    handleClickProfile?: () => void;
    phoneNumber?: string;
    agentTagged?: boolean;
}

const UserProfileInfo = ({
    id,
    companyId,
    companyLogo,
    name,
    isBankVerified,
    isVerified,
    rating,
    isAadhaarVerified,
    isGstVerified,
    isPanVerified,
    isMember,
    serviceType,
    handleClickProfile,
    phoneNumber,
    agentTagged
}: UserProfileInfoProps) => {
    const router = useRouter();
    const { setHistoryLocation } = useContext(AppContext);
    const { checkPermission, isCsDashboardUser } = useCsDashboard();
    const onClickProfile = () => {
        if (checkPermission(ADMIN_OPERATION.tagAgent)) {
            return;
        }
        handleClickProfile?.();
        setHistoryLocation?.(router.asPath);
        router.push(`${ROUTES.transporterProfile.getUrlWithId(companyId)}`);
    };

    const restrictedView = checkPermission(ADMIN_OPERATION.tagAgentManagerUI);
    const showPhoneNumber = restrictedView ? !!agentTagged : true;

    const PhoneNumberSlot = (
        <IfCanAccess action={ADMIN_OPERATION.userPhoneNumberVisibilityUI} alsoCheck={!!phoneNumber && showPhoneNumber}>
            <Flex alignItems="center" gap={10}>
                <span className={Styles.phoneIcon}>
                    <Phone />
                </span>
                <Typography weight="semibold" size="xs" color={COLORS.GREY_700}>
                    {phoneNumber}
                </Typography>
            </Flex>
        </IfCanAccess>
    );
    return (
        <Flex className={Styles.main} alignItems="center" gap={rating ? 16 : 6}>
            <div className={Styles.logoWrapper} id={`${id}--companyLogo`} onClick={onClickProfile}>
                <AvatarImage className={Styles.logo} imageUrl={companyLogo} firstName={name} size="s" />
                <div className={Styles.rating}>{Boolean(rating) ? <UserRating rating={rating} /> : <></>}</div>
            </div>

            <div className={Styles.company}>
                <Typography
                    id={`${id}--companyName`}
                    weight="semibold"
                    size="sm"
                    color={COLORS.GREY_900}
                    data-one-line-text
                    title={name}
                >
                    {name}
                </Typography>
                <div className={Styles.companyDesc}>
                    {!!isBankVerified && <BankVerifiedIcon />}
                    {!!(isVerified || isAadhaarVerified || isGstVerified || isPanVerified) && !isMember && (
                        <VerifiedIcon />
                    )}
                    {!!isMember && <MembershipTag />}
                    <Typography
                        weight="semibold"
                        size="xs"
                        color={COLORS.GREY_700}
                        id={`${id}--serviceType`}
                        data-service-type
                        data-one-line-text
                        title={CompanyServiceTypeMap[serviceType as CompanyServiceType]}
                    >
                        {CompanyServiceTypeMap[serviceType as CompanyServiceType]}
                    </Typography>
                </div>
                {PhoneNumberSlot}
            </div>
        </Flex>
    );
};

export default UserProfileInfo;
