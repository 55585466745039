import React from "react";
import Styles from "./MarketplaceLoadLorryQuickInfo.module.scss";
import { LORRY_CAPACITY_UNIT_TYPES, LORRY_CATEGORIES_ENUM, getLorryDetailsByType } from "@vahak/core/dist/constants";
import Typography, { TypographyProps, WeightType } from "@vahak/core-ui/dist/components/Typography";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";

//Assets
import LoadIcon from "@vahak/core/dist/icons/load-grey.svg";
import LorryIcon from "@vahak/core/dist/icons/lorry-icon-grey.svg";
import TyreIcon from "@vahak/core/dist/icons/tyre.svg";
import FuelIcon from "@vahak/core/dist/icons/fuel.svg";
import TonneIcon from "@vahak/core/dist/icons/tonne.svg";
import FeetIcon from "@vahak/core/dist/icons/feet.svg";

import { filteredArray } from "@vahak/core-ui/dist/methods/array";
import { capitalize } from "@vahak/core/dist/methods/capitalize";
import COLORS from "@vahak/core-ui/dist/constants/colors";

interface baseType extends Pick<TypographyProps, "size" | "mSize"> {
    type?: "load" | "lorry";
    id?: string;
    fontWeight?: WeightType;
    lorryType?: LORRY_CATEGORIES_ENUM;
}
interface MarketplaceLoadQuickInfoProps extends baseType {
    type: "load";
    material: string;
    quantity?: number;
}
interface MarketplaceLorryQuickInfoProps extends baseType {
    type: "lorry";
    material?: string;
    capacity?: number;
    tyres?: number;
    lorrySize?: number;
    lorrySizeText?: string;
    bodyType?: string;
    fuelType?: string;
    showLorryLabel?: boolean;
}

type Props = MarketplaceLoadQuickInfoProps | MarketplaceLorryQuickInfoProps;

const MarketplaceLoadLorryQuickInfo = ({
    id = "",
    type,
    fontWeight,
    lorryType,
    size = "xs",
    mSize = "xs",
    ...props
}: Props) => {
    const {
        bodyType,
        tyres,
        lorrySize,
        lorrySizeText,
        capacity,
        fuelType,
        showLorryLabel = true
    } = props as MarketplaceLorryQuickInfoProps;
    const { material, quantity } = props as MarketplaceLoadQuickInfoProps;

    const { label, unit, unitSingular } = getLorryDetailsByType(lorryType!) ?? {};

    const getLorryDetails = () => {
        return [
            (label || bodyType) && showLorryLabel
                ? {
                      icon: <LorryIcon />,
                      label: `${label}${bodyType ? " . " + bodyType : ""}`,
                      itemId: "lorry-type"
                  }
                : undefined,
            capacity
                ? {
                      icon: <TonneIcon />,
                      label: `${capacity} ${
                          capacity > 1
                              ? unit === LORRY_CAPACITY_UNIT_TYPES.TONNES
                                  ? "Tonnes"
                                  : "Kilolitres"
                              : unitSingular
                      }`,
                      itemId: "capacity"
                  }
                : undefined,
            tyres
                ? {
                      icon: <TyreIcon />,
                      label: tyres + " tyres",
                      itemId: "tyres"
                  }
                : undefined,
            lorrySize
                ? {
                      icon: <FeetIcon />,
                      label: `${lorrySize} ${lorrySizeText === "ft" ? "feet" : lorrySizeText}`,
                      itemId: "size"
                  }
                : undefined,
            fuelType
                ? {
                      icon: <FuelIcon />,
                      label: capitalize(fuelType?.toLowerCase()),
                      itemId: "fuel-type"
                  }
                : undefined
        ];
    };

    const details: {
        icon: React.ReactNode;
        label: string;
        itemId: string;
    }[] = filteredArray(
        type === "load"
            ? [
                  material
                      ? {
                            icon: <LoadIcon />,
                            label: `${material} ${
                                quantity
                                    ? `(${quantity} ${
                                          quantity > 1
                                              ? unit === LORRY_CAPACITY_UNIT_TYPES.TONNES
                                                  ? "Tonnes"
                                                  : "Kilolitres"
                                              : unitSingular
                                      })`
                                    : ""
                            }`,
                            itemId: "material"
                        }
                      : undefined
              ]
            : [...(lorryType != undefined ? getLorryDetails() : [])]
    );

    return (
        <Flex display="inline-flex" gap={16} alignItems="center">
            {details?.map((dt, index) => {
                const { icon: Icon, label, itemId } = dt;
                return (
                    <Flex gap={5} alignItems="center" key={index}>
                        <IconWrapper gap={"5px"} key={"icon"} height={16} width={16}>
                            {Icon}
                        </IconWrapper>
                        <Typography
                            size={size}
                            mSize={mSize}
                            weight={fontWeight ? fontWeight : "medium"}
                            color={type === "load" ? COLORS.GREY_600 : COLORS.GREY_700}
                            id={`${id}--${itemId}`}
                        >
                            {label}
                        </Typography>
                    </Flex>
                );
            })}
        </Flex>
    );
};

export default MarketplaceLoadLorryQuickInfo;
